<template>
  <div class="container">
    <van-row class="top">
      <van-row class="top-left">
        <van-image
          round
          width="6rem"
          height="6rem"
          :src="require('../../assets/img/btc.png')"
        />
      </van-row>
      <van-row class="top-right">
        <van-row @click="tosetting" style="font-size: 1rem; height: 1.5rem">
          <b v-if="user.nickname">{{ user.nickname }}</b>
          <b v-else>{{ $t('home.nickname') }}</b>
        </van-row>
        <van-row style="font-size: 1rem; height: 1.5rem">
          <span>UID:{{ user.username }}</span>
        </van-row>
        <van-row style="font-size: 1rem; height: 1.5rem">
          <span style="font-size: 15px; font-weight: 500"
            >{{ $t('assets.credit') }}:<span
              style="font-size: 16px; font-weight: 800"
              >{{ score }}</span
            >
          </span>
        </van-row>
        <van-row>
          <span style="font-size: 16px; font-weight: 800; color: red">{{
            $t(level)
          }}</span>
        </van-row>
      </van-row>
    </van-row>
    <div class="content">
      <van-grid :column-num="1">
        <van-grid-item>
          <div style="display: flex; width: 100%">
            <van-row style="flex: 1">
              <p style="font-size: 0.8rem; margin-bottom: 10px">
                {{ $t('assets.zassets') }}({{ currency.toUpperCase() }})：
              </p>
              <b style="font-size: 1.2rem; text-align: center; color: red">{{
                (balance.usd * currencyhuilv) | priceformat
              }}</b>
            </van-row>
            <van-row style="flex: 1; text-align: center">
              <p style="font-size: 0.8rem; margin-bottom: 10px">
                总收益({{ currency.toUpperCase() }})：
              </p>
              <b style="font-size: 1.2rem; color: red">{{
                income | priceformat
              }}</b>
            </van-row>
          </div>
        </van-grid-item>
      </van-grid>
      <!--  <van-grid :column-num="2">-->
      <!--    <van-grid-item>-->
      <!--      <van-row>-->
      <!--        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('assets.tprofit') }}({{ currency.toUpperCase() }})</p>-->
      <!--        <b>{{ tongji['profit']*currencyhuilv | priceformat}}</b>-->
      <!--      </van-row>-->
      <!--    </van-grid-item>-->
      <!--    <van-grid-item>-->
      <!--      <van-row>-->
      <!--        <p style='display: flex;margin-bottom: 0.3rem;font-size: 0.7rem;'>{{ $t('assets.tamount') }}({{ currency.toUpperCase() }})</p>-->
      <!--        <b>{{ tongji['price']*currencyhuilv | priceformat}}</b>-->
      <!--      </van-row>-->
      <!--    </van-grid-item>-->
      <!--  </van-grid>-->
      <div>
        <van-cell-group>
          <van-cell
            center
            icon="balance-pay"
            :title="$t('assets.charge')"
            is-link
            @click="toserver"
          />
          <van-cell
            center
            icon="cash-back-record"
            :title="$t('assets.withdraw')"
            is-link
            to="/assets/withdraw"
          />
          <van-cell
            center
            icon="refund-o"
            title="收益"
            is-link
            to="/trade/income"
          />
          <van-cell
            center
            icon="exchange"
            :title="$t('assets.duihuan')"
            is-link
            to="/trade/shopping/trading"
          />
          <!-- <van-cell
          center
          icon="question-o"
          :title="$t('home.help')"
          is-link
          to="/person/setting"
        /> -->
          <van-cell
            center
            icon="service-o"
            :title="$t('home.serverurl')"
            is-link
            :url="serverurl"
          />
          <van-cell
            center
            icon="setting-o"
            :title="$t('home.set')"
            is-link
            to="/person/setting"
          />
          <van-cell
            center
            icon="replay"
            :title="$t('home.logout')"
            is-link
            @click="logout"
          />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      selectLang: '',
      selectLangOption: [
        { text: '中文', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'русский язык', value: 'ru' },
        { text: 'Français', value: 'fr' },
        { text: 'Español', value: 'es' },
        { text: 'Italiano', value: 'it' },
      ],
      lang: localStorage.getItem('lang') || 'zh',
      currency: localStorage.getItem('currency') || 'RMB',
      level: '',
      serverurl: '',
      user: {},
      editpass: {
        oldpass: '',
        newpass: '',
        newpass2: '',
      },
      score: 0,
      currencyhuilv: 1,
      userinfo: {},
      balance: {},
      tongji: [],
      msgcount: 0,
      notifyshow: false,
      websocket: null,
      wk: null,
      active: 0,
      serverurl: '',
      income: 0,
    }
  },
  created() {
    this.getdata()
    this.getinfo()
    this.getserver()
    this.getIncome()
  },
  methods: {
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data.serverurl
          this.user = data.data.user
          switch (data.data.level) {
            case '1':
              this.level = 'home.vip1'
              break
            case '2':
              this.level = 'home.vip2'
              break
            case '3':
              this.level = 'home.vip3'
              break
            case '4':
              this.level = 'home.vip4'
              break
            case '5':
              this.level = 'home.vip5'
              break
          }
          if (data.data.user.random_password === '1') {
            this.suiji = true
          }
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          let that = this
          this.score = +data.data.userinfo.credit_score
          this.userinfo = data.data.userinfo
          this.balance = data.data.balance
          this.tongji = data.data.tongji
          let units = data.data.unitinfo
          units.forEach(function (value, index, units) {
            if (units[index].unit == that.currency) {
              that.currencyhuilv = units[index].huilv
            }
          })
        }
      }
    },
    // 获取客服链接
    async getserver() {
      const { data } = await this.$http.get('/home/index/serviceurl')
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data
        }
      }
    },
    // 总收益
    async getIncome() {
      const { data } = await this.$http.get('/home/trade/get_income')
      if (data) {
        if (data.code === 200) {
          this.income = data.data.total_income
        }
      }
    },
    // 去设置页
    tosetting() {
      this.$router.push('/person/setting')
    },
    // 退出
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
    },
    // 客服页面
    toserver() {
      window.location.href = this.serverurl
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  background-color: #222642;
}
// 弹出层
.top {
  height: 10rem;
  display: flex;
  align-items: center;
  background: url(../../assets/img/mine_bg.png) no-repeat;
  background-size: cover;
  //justify-content: center;
  .top-left {
    margin-right: 2%;
    float: left;
    padding: 1rem;
  }
  .top-right {
    text-align: left;
    font-size: 1.33333rem;
    line-height: 2.13333rem;
  }
  b,
  span {
    color: #fff;
  }
}
/deep/.van-grid {
  .van-grid-item__content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .van-hairline::after {
    border: none;
  }
}
.van-hairline--top::after {
  width: 0;
}
.content {
  padding: 10px 20px 0;
  border-radius: 10px;
}

/deep/.van-cell-group {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .van-cell:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}

.van-cell {
  height: 3.2rem;
  font-size: 0.93333rem;
}
.van-cell__title {
  text-align: left;
}
.van-cell__value {
  color: #000;
}
</style>
